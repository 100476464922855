* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-padding-top: 100px;
  -webkit-user-drag: none;
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--white) !important;
}
:root {
  --primary-50: #fff0f3;
  --primary-100: #ffdde4;
  --primary-200: #ffc0cc;
  --primary-300: #ff94a9;
  --primary-400: #ff5778;
  --primary-500: #ff234e;
  --primary-600: #ff1744;
  --primary-700: #d7002a;
  --primary-800: #b10325;
  --primary-900: #920a24;
  --primary-950: #500010;
  --white: #fff;
  --black: #000;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

a {
  text-decoration: none;
}
.bgprimary {
  background-color: var(--primary-600) !important;
}
.darkbg {
  background-color: var(--primary-950) !important;
}
.lightbg {
  background-color: var(--primary-50) !important;
}

/* Button */
.primarybtn {
  background-color: var(--primary-600);
  border-radius: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  border: 1px solid var(--primary-600);
}
.primarybtn:hover {
  transition: 0.3s ease-in;
  color: var(--primary-600);
  background-color: transparent;
  border: 1px solid var(--primary-600);
}
.secondarybtn {
  color: var(--primary-400);
  background-color: transparent;
  border: 1px solid var(--primary-400);
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
}
.secondarybtn:hover {
  background-color: var(--primary-400);
  color: var(--white);
  transition: 0.3s ease-in;
}
.lighbgbtn {
  background-color: var(--primary);
  border-radius: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  border: 1px solid var(--primary);
}
.whitebtn {
  background-color: var(--white);
  border-radius: 0;
  color: var(--primary-950);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
}
.whitebtn:hover {
  background-color: var(--primary-400);
  color: var(--white);
  transition: 0.3s ease-in;
}

/* NAV */
.navbar-light .navbar-toggler {
  border-color: var(--primary-900);
}
.navbar-light .navbar-toggler-icon {
  background-image: url(./assets/Images/menuIcon.svg);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-bg {
  padding: 8px 0;
}
.navold-bg {
  background: var(--primary-100) !important;
}
.navblur {
  /* background: rgba(255, 255, 255, 0.25); */
  backdrop-filter: blur(4px);
  /* -webkit-backdrop-filter: blur(4px); */
}
.navbar-light .navbar-nav .nav-link {
  color: var(--primary-900);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.navbar-light .navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 0;
  height: 1.5px;
  background-color: var(--primary-400);
  transition: width 0.4s ease-in-out;
}
.navbar-light .navbar-nav .nav-link:hover::before {
  width: 100%;
}
.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: var(--primary-400);
  display: inline-block;
}
.navbar-light .navbar-nav .nav-item.active .nav-link::before {
  width: 100%;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-400);
  transition: 0.4s ease-in;
}
.self-nav .navbar-brand img {
  width: 200px;
}
.navbar-brand,
nav-link {
  margin-bottom: 0;
}
.self-nav {
  padding: 12px 0px;
  border-radius: 0;
}
.self-nav ul.navbar-nav li p {
  margin: 0 15px;
}
.self-nav ul.navbar-nav li p.active {
  color: #0072fa !important;
  font-weight: 600;
  /* border-radius: 0px 0px 5px 5px; */
  border-bottom: 2px solid #2863d5;
}
.navbtn {
  position: absolute;
  right: 0;
}

/* Commantext */
.commantext h4 {
  color: var(--primary-950);
  font-size: 40px;
  font-weight: 600;
}
.commantext h4 span {
  color: var(--primary-600);
}
.commantext p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.comman_space {
  padding: 80px 0;
}
.commanOther p a {
  color: var(--primary-400);
}
/* Commantext */

/* HEROSEC */
.blackbg {
  background-color: var(--white);
}
.hero-sec {
  padding: 150px 0 100px;
  background-color: var(--primary-100);
  /* background-repeat: no-repeat;
  background-image: url(./assets/Images/homebg.svg);
  background-size: cover;
  background-position: center center; */
  /* background-attachment: fixed; */
}
.hero-sec h1 {
  color: #000013;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 700;
}
.hero-sec h1 span {
  color: var(--primary-600);
}
.mybtn .form-control {
  border-radius: 0;
  height: 50px;
}
.mybtn .whitebtn {
  height: 50px;
}
.mybtn .form-control:focus {
  border-color: var(--primary-100);
  outline: 0;
  box-shadow: none;
}
.mybtn {
  margin: auto;
  width: 75%;
  background-color: var(--primary-950);
  padding: 30px;
  transform: translateY(-55px);
}
.mybtnsub {
  gap: 10px;
  display: flex;
  align-items: start;
}
.message {
  color: red;
}
.message_size{
  font-size: 20px;
  font-weight: 600;
}
.notFound_sec{
  padding: 50px 0;
}
/* HEROSEC */

/* GETAPP */
.get_app {
  padding: 40px 0;
}
.get_app h4 {
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
}
.get_app p {
  color: var(--white);
  font-size: 18px;
}
/* GETAPP */

/*  START */
.start_withsub {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.start_withbox {
  display: flex;
  gap: 18px;
  align-items: center;
  border-radius: 0;
  padding: 20px;
  background-color: var(--primary-50);
  cursor: pointer;
}
.start_withbox:hover {
  transition: 0.8s ease-in;
  transform: translateX(10px) translateY(-10px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.start_withicon img {
  width: 300px;
}
.start_withbox h5 {
  color: var(--primary-950);
  font-size: 22px;
  font-weight: 700;
}
.start_withbox p {
  color: var(--primary-900);
  font-size: 16px;
  margin-bottom: 0;
}
/*  START */

/* ABOUT */
/* Commantext */
.aboutus_textmain {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.aboutus_text {
  text-align: center;
  padding: 10px;
  background-color: var(--primary-50);
  border: 1px solid var(--primary-100);
}
.aboutus_text h4 {
  color: var(--secondary);
  font-size: 30px;
  font-weight: 700;
}
.aboutus_text p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}

.aboutus_img img {
  border-radius: 0;
}

.aboutus_img img {
  width: 300px;
}
.about_detailmain {
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-flow: dense;
}
.about_detail {
  text-align: center;
}
.about_detail img {
    padding-bottom: 10px;
    width: 80px;
}
.about_detail h4 {
  font-size: 18px;
  font-weight: 600;
}
.about_detail p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
/* ABOUT */

/* WHYCHOOSE */
.whychoosemain {
  background: radial-gradient(
    circle,
    var(--primary-100) 0%,
    var(--primary-300) 100%
  );
  border: 1px solid var(--primary-600);
}
.why_choosebox {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.why_choosebox::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--primary-900);
  transition: width 0.4s ease-in-out;
}
.why_choosebox:hover::before {
  width: 100%;
}
.why_choosebox h5 {
  color: var(--secondary);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.why_choosebox p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.whychoose_icon {
  width: 100px;
}
/* WHYCHOOSE */

/* ACCORD */
.accordsec {
  padding: 100px 0;
}
.accordsec .product-text p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.selfaccord .accordion-item {
  border-radius: 0;
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
}
.selfaccord .accordion-item:first-child {
  border-top: 1px solid #e1e1e1;
}
.selfaccord .accordion-item {
  padding: 10px;
  background: transparent;
}
.selfaccord .accordion-button {
  padding: 10px;
  color: var(--black);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
}
.selfaccord .accordion-button:not(.collapsed) {
  color: var(--black);
  box-shadow: none;
}
.selfaccord .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-flush .accordion-item:last-child {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
/* ACCORD */

/*  */
.contact_main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.contact_text h4 {
  font-size: 28px;
  color: var(--primary-950);
  font-weight: 700;
  margin-bottom: 20px;
}
.contact_text p {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--primary-black);
}
.contact_text p a {
  color: var(--primary-600);
}
.contact_form {
  padding: 20px 0 0;
}
.form_field {
  padding-bottom: 20px;
}
.form_field .form-control {
  padding: 10px;
  border-radius: 0;
}
.form_field .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--primary-400);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--primary-300);
}
.contact_iconBox {
  /* background-color: var(--white); */
  border-radius: 0;
  padding: 12px;
  text-align: center;
}
.contact_iconBox p {
  font-size: 16px;
  color: var(--primary-800);
  font-weight: 700;
  margin-bottom: 0;
}
.source_box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  gap: 10px;
}
.source_boxsub {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-radius: 0;
  border: 1px solid var(--primary-400);
}
.source_boxsub:hover {
  transition: 0.5s ease-in;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
  background-color: var(--primary-50);
}
.source_icon {
  border-radius: 0;
  height: 50px;
  width: 50px;
  background-color: var(--primary-500);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.source_boxsub h4 {
  color: var(--primary-950);
  font-size: 24px;
  font-weight: 600;
}
.source_boxsub p,
.source_boxsub p a {
  color: var(--primary-90);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.iframe {
  height: 100%;
  width: 100%;
  border-radius: 0;
}
/*  */

/* Footer */
footer {
  background-color: var(--primary-950);
  color: var(--white);
}
.footer {
  padding: 40px 0;
}
.link-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.link-detail p {
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-50);
}

.link-detail p:hover {
  color: var(--primary-600);
  border-bottom: 1px solid var(--primary-600);
}
.link-detail p a {
  cursor: pointer;
  text-decoration: none;
  color: #dee2ee;
  font-size: 16px;
  font-weight: 400;
}
.link-detail p a:hover {
  color: var(--primary-600);
}
.foot_iconmain {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.foot_icon a {
  color: var(--primary-950);
}
.foot_icon {
  background-color: var(--primary-50);
  height: 40px;
  width: 40px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot_icon:hover {
  transition: 0.5s ease-in;
  border: 1px solid var(--primary-50);
  background-color: transparent;
}
.foot_icon:hover a {
  transition: 0.5s ease-in;
  color: var(--primary-50);
}
.copy-rightMain {
  background-color: var(--primary-950);
}
.copy-right {
  padding: 15px 0;
  border-top: 1px solid var(--primary-50);
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-right p {
  /* text-align: center; */
  margin-bottom: 0;
  color: var(--white);
}
.copy-right p a {
  margin-bottom: 0;
  color: var(--white);
}

/* Footer */

/* ORDER  */
.order_main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.order_sub {
  padding: 10px 0;
  display: flex;
  align-items: start;
  border-bottom: 1px solid var(--black);
  padding-bottom: 10px;
}
.order_sub h6 {
  min-width: 200px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
.order_sub p {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-black);
  margin-bottom: 0;
}
.itemnot h4 {
  font-size: 20px;
  font-weight: 500;
}
.detailcard {
  padding: 20px;
  background-color: var(--primary-white);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.detail_heading {
  color: var(--primary-600);
  font-size: 20px;
  font-weight: 600;
}
/* ORDER */

/* policy */
.policy_main {
  padding-bottom: 20px;
}
.policy_main h4 {
  font-size: 18px;
  font-weight: var(--primary-950);
  font-weight: 600;
}
.policy_main p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: var(--primary-black);
}
.policy_ul {
  font-size: 14px;
  font-weight: var(--primary-black);
}
/* policy */

/* Media */

/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
  .heroimg img {
    width: 500px;
  }
}

/* 1280 */
@media (max-width: 1439px) {
  .self-nav ul.navbar-nav li p {
    margin: 0 15px;
  }
  .hero-sec h1 {
    margin-bottom: 20px;
    font-size: 34px;
  }
}

/* 992 */
@media (max-width: 1279px) {
  .heroimg img {
    width: 495px;
  }
  .nav-bg {
    padding: 0px 0;
  }
  .self-nav ul.navbar-nav li p {
    margin: 0 5px;
  }
  .comman_space {
    padding: 50px 0;
  }
  .commantext h4 {
    font-size: 30px;
  }
  .why_choosebox h5 {
    font-size: 16px;
  }
  .footer {
    padding: 30px 0;
  }
  .aboutus_text h4 {
    font-size: 24px;
  }
}

/* 768 */
@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 8px 30px 8px 0;
  }
  .navbar-nav {
    margin-top: 20px;
  }
  .navbtn {
    position: unset;
  }
  .self-nav .navbar-nav .nav-item a {
    display: inline-block;
  }
  .hero-sec p {
    font-size: 18px;
  }
  .heroimg img {
    width: 401px;
  }
  .hero-sec {
    padding: 120px 0 80px;
  }
  .get_app h4 {
    font-size: 20px;
  }
  .get_app p {
    font-size: 16px;
  }
  .commantext h4 {
    font-size: 24px;
  }
  .start_withsub {
    grid-template-columns: repeat(2, 1fr);
  }
  .start_withbox h5 {
    font-size: 20px;
  }
  .start_withbox p {
    font-size: 16px;
  }
  .aboutus_img img {
    width: 300px;
    margin-bottom: 20px;
  }
  .why_choosebox h5 {
    font-size: 20px;
  }
  .selfaccord .accordion-item {
    padding: 4px;
    border-radius: 0;
  }
  .contact_text h4 {
    font-size: 24px;
    padding-top: 15px;
  }
  .aboutus_text h4 {
    font-size: 20px;
  }
  .contact_main {
    grid-template-columns: repeat(1, 1fr);
  }
  .order_main {
    grid-template-columns: repeat(1, 1fr);
  }
  .notFound_sec{
    padding: 20px 0;
  }
  .notFound_sec img{
    width: 150px;
  }
  .message_size{
    font-size: 16px;
  }
}

/* 576 */
@media (max-width: 767px) {
  .self-nav {
    border-radius: 0;
  }
  .hero-sec {
    padding: 100px 0 50px;
  }
  .hero-sec h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .hero-sec h4 {
    font-size: 14px;
  }
  .hero-sec p {
    font-size: 16px;
  }
  .mybtn {
    width: 100%;
    padding: 20px;
    transform: translateY(-45px);
  }
  .mybtn .form-control {
    border-radius: 0;
    height: 35px;
  }
  .mybtn .whitebtn {
    border-radius: 0;
    height: 35px;
    padding: 0 10px;
  }
  .primarybtn,
  .secondarybtn,
  .lighbgbtn,
  .whitebtn {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 0;
  }
  .get_app {
    padding: 24px 0;
  }
  .start_withbox {
    padding: 20px 20px;
  }
  .start_withsub {
    grid-template-columns: repeat(1, 1fr);
  }
  .comman_space {
    padding: 20px 0;
  }
  .aboutus_img img {
    width: 254px;
  }
  .whychoosicon {
    width: 180px;
    padding-bottom: 20px;
  }
  .why_choosebox {
    padding: 2px 0;
  }
  .why_choosebox h5 {
    font-size: 16px;
  }
  .contact_icon img {
    padding-top: 20px;
  }
  .commantext h4 {
    font-size: 22px;
  }
  .link-detail p {
    margin-bottom: 5px;
  }
  .footer {
    padding: 20px 0;
  }
  .source_boxsub {
    gap: 6px;
  }
  .source_boxsub h4 {
    font-size: 20px;
  }
  .source_boxsub p,
  .source_boxsub p a {
    font-size: 14px;
  }
  .copy-right {
    padding: 20px 0;
  }
  .copy-right p {
    font-size: 14px;
  }
  .link-detail {
    gap: 15px;
  }
  .about_detailmain {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  .aboutus_text p {
    font-size: 14px;
  }
}

/* 525 */
@media (max-width: 575px) {
  .navbar-nav {
    margin-top: 5px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 4px 8px;
  }
  .navbar-toggler {
    padding: 1px 7px;
  }
  .self-nav .navbar-brand img {
    width: 180px;
  }
  .commantext h4 {
    font-size: 20px;
  }
  .commantext p {
    font-size: 14px;
  }
  .start_withsub {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  .start_withbox {
    border-radius: 0;
  }
  .start_withbox h5 {
    font-size: 18px;
  }
  .start_withbox p {
    font-size: 14px;
  }
  .why_choosebox h5 {
    font-size: 18px;
  }
  .why_choosebox p {
    font-size: 14px;
  }
  .selfaccord .accordion-button {
    font-size: 14px;
  }
  .contact_text h4 {
    font-size: 20px;
  }
  .contact_text p {
    font-size: 14px;
  }
  .link-detail p {
    font-size: 14px;
  }
  .link-detail p a {
    font-size: 14px;
  }
  .link-detail h6 {
    margin-bottom: 6px;
  }
  .copy-right {
    flex-direction: column;
  }
  .order_sub h6 {
    min-width: 150px;
  }
  .detailcard {
    padding: 14px;
  }
  .detail_heading {
    font-size: 18px;
  }
  .order_sub h6 {
    font-size: 14px;
  }
  .order_sub p {
    font-size: 14px;
  }
}

/* 480 */
@media (max-width: 524px) {
}

/* 425 */
@media (max-width: 479px) {
  .hero-sec h1 {
    font-size: 18px;
  }
  .hero-sec p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .heroimg img {
    width: 260px;
    padding-bottom: 10px;
  }
  .get_app h4 {
    font-size: 18px;
  }
  .get_app p {
    font-size: 14px;
  }
  .commantext h4 {
    font-size: 18px;
  }
  .comman_space {
    padding: 15px 0;
  }
  .why_choosebox h5 {
    font-size: 16px;
  }
  .selfaccord .accordion-body {
    font-size: 14px;
  }
  .contact_text p {
    margin-bottom: 10px;
  }
  .contact_icon img {
    padding-top: 10px;
  }
  .start_withicon img {
    width: 74px;
  }
  .start_withbox {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .start_withicon img {
    width: 55px;
  }
  .aboutus_text h4 {
    font-size: 18px;
  }
  .aboutus_textmain {
    grid-template-columns: repeat(1, 1fr);
  }
  .about_detailmain {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
  .about_detail img {
    width: 50px;
  }
  .about_detail h4 {
    font-size: 16px;
  }
  .about_detail h4 {
    font-size: 14px;
  }
  .commanOther p{
    font-size: 14px;
  }
}

/* 375 */
@media (max-width: 424px) {
  .hero-sec h1 {
    font-size: 16px;
  }
  .start_withbox h5 {
    font-size: 16px;
  }
  .start_withbox {
    padding: 12px;
  }
  .contact_form {
    padding: 10px 0 0;
  }
  .form_field .form-control {
    padding: 6px;
  }
  .form_field {
    padding-bottom: 10px;
  }
  .aboutus_img img {
    width: 175px;
  }
  .whychoosicon {
    width: 140px;
    padding-bottom: 10px;
  }
  .order_sub h6 {
    min-width: 100px;
    font-size: 12px;
  }
  .order_sub p {
    font-size: 12px;
  }
}

/* 320 */
@media (max-width: 374px) {
}

/* 280 */
@media (max-width: 319px) {
}

.loader {
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--primary-950);
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.scale-up-hor-center {
  -webkit-animation: scale-up-hor-center 0.8s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
